<template>
  <div class="w-full">
    <div class="header-area relative w-full h-40 flex items-center justify-center bg-theme-color" v-if="index">
      <h2 class="font-bold text-4xl text-white">{{ header.titulo }}</h2>
    </div>
    <section class="container mx-auto py-16 font-medium text-xl">
      <div class="flex justify-center flex-wrap">
        <div class="px-4 py-2 rounded mx-2" v-for="sponsor in sponsors" :key="sponsor.nombre">
          <a :href="sponsor.descripcion" target="_blank" v-if="sponsor.descripcion !== '#'">
            <img :src="sponsor.logo" class="object-contain mx-auto h-24 w-full max-w-[200px] filter grayscale hover:grayscale-0" :alt="sponsor.nombre">
          </a>
          <img :src="sponsor.logo" class="object-contain mx-auto h-24 w-full max-w-[200px] filter grayscale hover:grayscale-0" :alt="sponsor.nombre" v-else>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "CtaSponsors",
  props: {
    sponsors: {
      type: Array,
      default: []
    },
    header: {
      required:true
    },
    index:{
      type: Boolean,
      default: false
    }
  },
}
</script>
<style>
  .header-area::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: var(--theme-color);
    bottom: -10px;
    left: calc(50% - 15px);
    z-index: 10;
    transform: rotate(45deg);
  }
</style>
