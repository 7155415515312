<template>
  <div class="w-full h-64 relative">
    <div class="container grid grid-cols-1 md:grid-cols-2 items-center mx-auto h-full">
      <h2 class="text-white md:text-3xl text-3xl px-10 font-bold tracking-normal text-center md:text-left" v-if="backend">{{ info.titulo }}</h2>
      <h3 class="text-white md:text-4xl text-3xl px-10 font-bold tracking-normal text-center md:text-left" v-else>{{ info.titulo }}</h3>
      <div class="flex justify-center">
        <a :href="info.link" target="_blank" v-if="blank">
          <button class="text-white bg-theme-color py-5 px-7 rounded-md">{{ info.cuerpo }}</button>
        </a>
        <a :href="info.link" v-else>
          <button class="text-white bg-theme-color py-5 px-7 rounded-md">{{ info.cuerpo }}</button>
        </a>
      </div>
    </div>
    <div class="absolute bg-text-color-dark right-0 top-0 w-full h-full z-[-1]">
      <img class="h-full w-full object-cover mix-blend-overlay" :src="info.imagen" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      info: {
        required: true
      },
      backend: {
        type: Boolean,
        default: false
      },
      blank: {
        type: Boolean,
        default: false
      }
    },
  }
</script>

<style scoped>

</style>
