<template>
  <div>
    <cta-hero :sliders="sliders" />
    <cta-sub-area :items="seccionesSubAreas" />
    <cta-description class="md:py-36" :descripcion="headerDescripcion" :has-link="true" :isImage="true" v-if="headerDescripcion" />
    <cta-video :detalle="headerVideo" v-if="headerVideo.titulo" />
    <cta-thematic :areas="areas" :header="headerAreas" />
    <cta-speakers :speakers="speakers" :informacion="headerSpeakers" v-if="speakers.length > 0" />
    <cta-date :fechas="fechas" :header="headerFechas" v-if="fechas.length > 0" />
    <cta-sponsors :sponsors="sponsors" :header="headerPatrocinadores" v-if="sponsors.length > 0" :index="true" />
  </div>
</template>

<script>
import CtaDescription from '../components/CtaDescription.vue';
import CtaHero from '../components/home/CtaHero.vue';
import CtaSpeakers from '../components/CtaSpeakers.vue';
import CtaSubArea from '../components/CtaSubArea.vue';
import CtaThematic from '../components/CtaThematic.vue';
import CtaVideo from '../components/CtaVideo.vue';
import CtaSponsors from "../components/CtaSponsors";
import CtaDate from "../components/CtaDate.vue"
import API from "@/api";
import {mapMutations, mapState} from "vuex";

export default {
  name: "Home",
  components: {
    CtaSponsors,
    CtaHero,
    CtaSubArea,
    CtaDescription,
    CtaVideo,
    CtaThematic,
    CtaSpeakers,
    CtaDate
  },
  data() {
    return {
      seccionesSubAreas: [],
      sliders: [],
      areas: [],
      speakers: [],
      sponsors: [],
      fechas: [],
      headerDescripcion: {},
      headerSpeakers: {},
      headerVideo: {},
      headerAreas: {},
      headerFechas: {},
      headerPatrocinadores: {},
    };
  },
  created() {
    this.setLoading(true);
    this.getInfo();
    this.getSliders();
    this.getTematicas();
    this.getSpeakers();
    this.getSponsors();
    this.getFechas();
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  methods: {
    ...mapMutations(["setLoading"]),
    async getSliders() {
      const { data } = await API.getSliders({ evento_fk: this.idEventoActual });
      this.sliders = data.results.sort((a, b) => a.orden - b.orden);
      this.setLoading(false);
    },
    async getInfo(){
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json"
      };
      const { data } = await API.getInfo(params);
      let items = data.results;
      for (let i in items){
        this.headerDescripcion = (items[i].codigo === 'IN01') ? items[i] : this.headerDescripcion;
        this.headerSpeakers = (items[i].codigo === 'IN02') ? items[i] : this.headerSpeakers;
        this.headerVideo = (items[i].codigo === 'IN03') ? items[i] : this.headerVideo;
        this.headerAreas = (items[i].codigo === 'IN04') ? items[i] : this.headerAreas;
        this.headerFechas = (items[i].codigo === 'IN05') ? items[i] : this.headerFechas;
        this.headerPatrocinadores = (items[i].codigo === 'PA01') ? items[i] : this.headerPatrocinadores;
        if(items[i].codigo === 'SSA'){
          this.seccionesSubAreas.push(items[i]);
        }
      }
    },
    async getTematicas() {
      const {data} = await API.getTematicas({evento_fk: this.idEventoActual});
      this.areas = data.results.sort((a, b) => a.orden - b.orden);
    },
    async getSpeakers() {
      const params = {
        "evento_fk" : this.idEventoActual,
        "index": 1,
        format:  "json"
      };
      const { data } = await API.getSpeakers(params);
      this.speakers = data.results
    },
    async getSponsors() {
      const { data } = await API.getSponsors({ evento_fk: this.idEventoActual });
      this.sponsors = data.results.sort((a, b) => a.orden - b.orden)
    },
    async getFechas() {
      const { data } = await API.getFechas({ evento_fk: this.idEventoActual });
      this.fechas = data.results
    },
  },
  watch: {
    idEventoActual() {
      this.getSliders();
      this.getTematicas();
      this.getSpeakers();
      this.getSponsors();
      this.getFechas();
    }
  },
};
</script>
