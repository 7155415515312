<template>
  <div class="w-full lg:h-96 h-72 relative ">
    <div class="container grid grid-cols-1 md:grid-cols-2 items-center mx-auto h-full">
      <h3 class="text-white md:text-5xl text-4xl font-bold text-center md:text-left">{{detalle.titulo}}</h3>
      <div class="flex justify-center">
        <button class="text-white bg-theme-color py-5 px-7 rounded-md -mt-10 md:mt-0" @click="toggleVideo"><Play :size="40"/></button>
      </div>
    </div>
    <div class="absolute bg-text-color-dark right-0 top-0 w-full h-full z-[-1]">
      <img class="h-full w-full object-cover mix-blend-overlay bg-fixed" :src="detalle.imagen" alt="">
    </div>
    <div class="fixed top-0 bottom-0 right-0 left-0 bg-gray-900 z-50 bg-opacity-70 flex items-center" v-if="isOpen">
      <div class="w-full flex justify-center">
        <span class="absolute text-gray-800 -mt-20 bg-gray-200 w-10 h-10 flex justify-center items-center rounded-full font-bold cursor-pointer" @click="toggleVideo">X</span>
        <iframe :src="detalle.link" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" loading="lazy" class="w-6/12 h-[400px]"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
  import Play from 'vue-material-design-icons/Play.vue';
  export default {
    components: { Play },
    data() {
      return {
        isOpen: false
      }
    },
    props: {
      detalle: {
        require: true
      },
    },
    methods: {
      toggleVideo() {
        this.isOpen = !this.isOpen
      }
    },
  }
</script>

<style scoped>

</style>
