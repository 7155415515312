<template>
  <nav class="w-[70%] top-0 left-0 h-screen bg-white z-20 overflow-y-auto relative" :class="start ? 'show-menu' : 'hide-menu' ">
      <ul class="flex flex-col font-medium mt-12">
        <li class="flex flex-col px-4 py-4 border-b-2" @click="onClicked" v-for="(item) in nav" :key="item.nombre">
          <router-link class="flex" :class="item.items.length ? 'pointer-events-none text-text-color-dark pb-1' : null" :to="item.path" :exact="item.path === '/'"><span class="flex items-center">{{item.nombre}} <ChevronDown v-if="item.items.length"/></span> </router-link>
          <ul v-if="item.items.length" class="">
            <li class="flex px-4" v-for="subItem in item.items" :key="subItem.nombre">
              <router-link class="flex py-3 w-full" :to="`${item.path}/${subItem.path}`">{{subItem.nombre}}</router-link>
            </li>
          </ul>
        </li>
      </ul>
      <Close class="absolute right-0 top-0 p-4" @click="onClicked" :size="35" />
  </nav>
</template>

<script>
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import Close from 'vue-material-design-icons/Close.vue';

  export default {
    components: {
      ChevronDown,
      Close
    },
    props: ["nav", "isActive"],
    data(){
      return {
        start: false
      }
    },
    methods: {
      onClicked(){
        this.start = false
        setTimeout(() => {
          this.$emit('closeDrawer')
        
        }, 700);
      }
    },
    watch:{
      isActive: {
        immediate: true,
        handler(newValue) {
          this.start = newValue
        }
      }
    }
  }
</script>

<style scoped>

.router-link-active {
  @apply text-theme-color;
}

.show-menu {
  animation: slide-in  1.2s;
}

.hide-menu {
  animation: slide-out  1.2s;
}

@-webkit-keyframes slide-in {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

@keyframes slide-in {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

/* @-webkit-keyframes slide-out {
  0% {
    left: 200%;
  }

  100% {
    left: 0;
  }
} */

@keyframes slide-out {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

</style>