<template>
  <div class="w-full">
    <div class="header-area relative w-full h-40 flex items-center justify-center bg-theme-color">
      <h2 class="font-bold text-4xl text-white">{{ header.titulo }}</h2>
    </div>
    <section class="container mx-auto md:py-32 py-20 px-10 md:px-0">
      <div class="list">
        <ul class="grid md:grid-cols-2">
          <li class="pb-3 flex" v-for="(area) in areas" :key="area.id">
            <span class="w-8 h-8 rounded-full flex justify-center items-center bg-theme-color-light"><Check
                class="text-theme-color"/></span> <span class="pl-7">{{ area.nombre }}</span>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import Check from 'vue-material-design-icons/Check.vue';

export default {
  components: {
    Check
  },
  props: {
    areas: {
      type: Array,
      default: []
    },
    header: {
      required:true
    }
  },
}
</script>

<style scoped>
.header-area::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: var(--theme-color);
  bottom: -10px;
  left: calc(50% - 15px);
  z-index: 10;
  transform: rotate(45deg);
}
</style>
