<template>
  <section class="bg-gray-100 md:py-32 py-16">
    <div class="container mx-auto">
      <div class="grid grid-cols-12 gap-5" v-if="informacion.titulo">
        <div class="md:col-span-8 col-span-12 text-center md:text-left px-10 md:px-0">
          <h2 class="text-theme-color font-medium text-4xl pb-4">{{informacion.titulo}}</h2>
          <p>{{informacion.cuerpo}}</p>
        </div>
        <div class="md:col-span-4 col-span-12 mx-auto ">
          <router-link tag="button" :to="informacion.link" class="text-white bg-theme-color py-5 px-7 rounded-md">
            Ver mas >>
          </router-link>
        </div>
      </div>
      <div class="flex justify-around flex-wrap" v-if="speakers.length">
        <div class="mt-20 mx-6 flex flex-col items-center" v-for="(speaker, index) in speakers" :key="`${speaker.nombre}-${index}`">
          <div class="w-48 h-48 container-img rounded-full overflow-hidden">
            <img class="" :src="speaker.foto" alt="speaker" />
          </div>
          <a :href="speaker.link" target="_blank">
            <strong class="mt-3">{{speaker.nombre}}</strong>
          </a>
          <small>{{speaker.tipo}}</small>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    speakers: {
      type: Array,
      default: []
    },
    informacion: {
      type: Object,
      default: null
    }
  },
};
</script>

<style>
</style>
