<template>
  <div class="bg-theme-color">
    <div class="container mx-auto grid grid-cols-1 md:grid-cols-3 h-full py-10">
      <div class="flex p-3 justify-center" v-for="(item, index) in items" :key="`${item.titulo}-${index}`">
        <a :href="item.link" target="_blank" v-if="item.link.includes('http')">
          <component class="transition delay-150 duration-300 ease-in-out transform hover:scale-90" :is="item.icono" :size="65" />
        </a>
        <a :href="item.link" v-else>
          <component class="transition delay-150 duration-300 ease-in-out transform hover:scale-90" :is="item.icono" :size="65" />
        </a>
        <div class="flex flex-col justify-center pl-4">
          <a :href="item.link" target="_blank" v-if="item.link.includes('http')">
            <strong class="text-xl">{{item.titulo}}</strong>
          </a>
          <a :href="item.link" v-else>
            <strong class="text-xl">{{item.titulo}}</strong>
          </a>
          <a :href="item.link" target="_blank" v-if="item.link.includes('http')">
            <span class="text-lg">{{item.cuerpo}}</span>
          </a>
          <a :href="item.link" v-else>
            <span class="text-lg">{{item.cuerpo}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentCopy from 'vue-material-design-icons/ContentCopy.vue';
import MonitorScreenshot from 'vue-material-design-icons/MonitorScreenshot.vue';
import BullhornOutline from 'vue-material-design-icons/BullhornOutline.vue';

  export default {
    components: {
      ContentCopy,
      BullhornOutline,
      MonitorScreenshot
    },
    props: {
      items: {
        type: Array,
        default: []
      },
    },
  }
</script>

<style scoped>
strong, span {
  @apply text-white;
}
</style>
