<template>
  <footer class="text-gray-600 body-font">
    <div class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
      <div class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left ">
        <div class="lg:w-1/3 md:w-1/3 w-full px-4">
          <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <img class="" :src="informaciondos.imagen" alt="Logo CTA" style="width: 70%">
          </a>
          <p class="mt-2 text-sm text-gray-500">{{ informaciondos.cuerpo }}</p>
        </div>
        <div class="lg:w-1/3 md:w-1/3 w-full px-4">
          <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <img class="" :src="informacion.imagen" alt="Logo CTA" style="width: 70%">
          </a>
          <p class="mt-2 text-sm text-gray-500">{{ informacion.cuerpo }}</p>
        </div>
        <div class="lg:w-1/3 md:w-1/3 w-full px-4">
          <h2 class="title-font font-bold text-gray-900 tracking-wide text-sm mb-3 pl-2">Contacto</h2>
          <nav class="list-none mb-10">
            <li v-for="item in contact" :key="item.id">
              <a class="text-gray-600 hover:text-text-color-dark py-2 flex"><component :is="item.icono" class="text-theme-color pr-2" /> {{item.titulo}}</a>
            </li>
          </nav>
        </div>
      </div>
    </div>
    <div class="bg-gray-100">
      <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p class="text-gray-500 text-sm text-center sm:text-left">© {{ anio }} Unillanos</p>
        <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
        <a class="text-gray-500" style="margin-left: 10px" target="_blank" :href="item.link" v-for="item in networks" :key="item.id">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24" v-if="item.nombre === 'Facebook'">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24" v-if="item.nombre === 'Twitter'">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24" v-if="item.nombre === 'Instagram'">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
          </svg>
          <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24" v-if="item.nombre === 'LinkedIn'">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
      </div>
    </div>
  </footer>
</template>

<script>
import ChevronRight from 'vue-material-design-icons/ChevronRight'
import MapMarker from 'vue-material-design-icons/MapMarker'
import Phone from 'vue-material-design-icons/Phone'
import At from 'vue-material-design-icons/At'
import Application from 'vue-material-design-icons/Application'

export default {
  name: "CtaFooter",
  components: {
    ChevronRight,
    MapMarker,
    Phone,
    At,
    Application
  },
  props: {
    networks: {
      type: Array,
      default: []
    },
    contact: {
      required: true
    },
    informacion: {
      required: true
    },
    informaciondos: {
      required: true
    },
  },
  data() {
    return {
      anio: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
.router-link-active {
  @apply text-theme-color;
}
</style>
