<template>
<section >
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full ">
      <h2 class="text-3xl font-bold mb-7">{{ header.titulo }}</h2>
    </div>
    <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      <div class="p-2 sm:w-1/2 w-full" v-for="fecha in fechas" :key="fecha.titulo">
        <div class="bg-gray-100 rounded flex p-4 h-full items-start" >
          <CalendarRange :size="34" class="text-theme-color mt-1"/>
          <div class="ml-5">
            <h2 class="text-theme-color text-lg title-font font-medium tracking-tight">{{fecha.fecha_inicio}}</h2>
            <p class="leading-relaxed text-text-color-dark font-medium">{{fecha.titulo}}</p>
            <p class="leading-relaxed text-text-color-dark">{{fecha.subtitulo}}</p>
            <p class="leading-relaxed text-text-color-dark text-sm" v-if="fecha.fecha_fin"><span class="font-medium">Fecha fin:</span> {{fecha.fecha_fin}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import CalendarRange from 'vue-material-design-icons/CalendarRange.vue';

export default {
  components: {
    CalendarRange
  },
  props: {
    fechas: {
      type: Array,
      default: []
    },
    header: {
      required:true
    }
  },
}
</script>

<style>

</style>
