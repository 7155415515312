<template>
  <div class="container-navs">
    <div class="container mx-auto px-6 absolute top-7 left-0 right-0 z-20">
      <header class="w-full ">
        <div class="main-header flex justify-between items-center relative container mx-auto h-20 md:h-28 w-full bg-white shadow-md" :class="sticky ? 'nav-sticky' : ''">
          <router-link to="/" class="p-4">
            <img class="w-40 md:w-48" :src="informacion.imagen" alt="Logo CTA">
          </router-link>
          <ul class="xl:flex hidden h-full text-sm font-medium">
            <li class="nav-item-first flex items-center px-4 hover:text-text-color-dark h-full relative" v-for="(item) in nav" :key="item.nombre">
              <router-link class="flex items-center h-full" :class="item.items.length ? 'pointer-events-none' : null" :to="item.path" :exact="item.path === '/'"><span class="flex items-center">{{item.nombre}} <ChevronDown v-if="item.items.length"/></span> </router-link>
              <ul v-if="item.items.length" class="sub-menu">
                <li class="flex border-t-2 shadow-md items-center px-4 hover:text-theme-color bg-white" v-for="subItem in item.items" :key="subItem.nombre">
                  <router-link class="flex items-center py-4 w-full" v-if="item.path !== '/eventos'" :to="{ path: `${item.path}/${subItem.path}`, query: { evento: idEventoActual ? idEventoActual : '' } }">{{subItem.nombre}}</router-link>
                  <router-link class="flex items-center py-4 w-full" :class="eventoSeleccionado.id === subItem.id ? '!text-theme-color' : null" @click.native="changeEvento(subItem)" v-else :to="{ path: '/', query: { evento: subItem.id } }">{{subItem.nombre}} <span v-if="subItem.actual === '1'" class="actual" >Actual</span></router-link>
                </li>
              </ul>
            </li>
          </ul>
          <Menu class="block xl:hidden pr-5" @click="toggleMenu" :size="40"/>
        </div>
      </header>
    </div>
    <div class="w-full bg-gray-900 h-screen fixed left-0 top-0 bg-opacity-75 z-40" v-if="mobileToggle">
      <cta-nav-mobile :nav="nav" :isActive="mobileToggle" @closeDrawer="toggleMenu" />
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState, mapGetters} from "vuex"
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import Menu from 'vue-material-design-icons/Menu.vue';
import CtaNavMobile from './CtaNavMobile.vue';
  export default {
    components: {
      ChevronDown,
      Menu,
      CtaNavMobile
    },
    data() {
      return {
        sticky: false,
        mobileToggle: false,
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
      this.nav[1].items = this.eventos
    },
    props: {
      informacion: {
        required: true
      },
      nav :{
        type: Array,
        required: true
      }
    },
    computed: {
      ...mapState(["idEventoActual", "eventos"]),
      ...mapGetters(["eventoSeleccionado"]),
    },
    methods: {
      ...mapMutations(["setIdEventoActual"]),
      handleScroll() {
        if (window.scrollY > 70) {
          this.sticky = true
        } else if (window.scrollY < 70) {
          this.sticky = false
        }
      },
      toggleMenu() {
        this.mobileToggle = !this.mobileToggle
      },
      changeEvento(evento){
        this.setIdEventoActual(evento.id)
      }
    }
  }
</script>

<style scoped>
.main-header::before {
  content: '';
  left: -10px;
  width: 10px;
  @apply absolute bg-theme-color h-full;
}

.main-header::after {
  content: '';
  right: -10px;
  width: 10px;
  @apply absolute bg-theme-color h-full;
}

.router-link-active {
  @apply text-text-color-dark;
}

.nav-item-first .router-link-active::before {
  @apply absolute bottom-0 left-1/2 w-0 h-0;
  content: '';
  transform: translateX(-50%);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 6px solid var(--theme-color);
}

.router-link-exact-active {
  @apply text-theme-color;
}

.nav-item-first:hover .sub-menu {
  top: 100%;
  @apply visible opacity-100 transform-gpu translate-y-0;
}

.nav-item-first:hover::before {
  @apply absolute bottom-0 left-1/2 w-0 h-0;
  content: '';
  transform: translateX(-50%);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 6px solid var(--theme-color);
}

.sub-menu {
  top: 110%;
  @apply flex-col top-28 absolute left-0 border-t-4 border-theme-color opacity-0 invisible translate-y-6;
  min-width: 200px;
}

.nav-sticky {
  @apply fixed left-0 top-0 right-0;
  z-index: 30;
  animation: sticky  1.2s;
}

.actual {
  @apply bg-red-100 text-theme-color rounded-full px-2 ml-auto;
}

@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

@keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}
</style>
