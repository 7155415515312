 <template>
    <div id="app">
        <div v-if="loading"
             class="fixed bg-gray-50 bg-opacity-90 top-0 right-0 left-0 bottom-0 z-50 flex justify-center items-center">
            <div class="lds-dual-ring"></div>
        </div>
        <cta-nav v-if="eventos.length" :informacion="logo" :nav="nav" />
        <router-view v-if="render" />
        <cta-call-action :info="infoCallAction" />
        <cta-footer :networks="networks" :contact="contact" :informacion="logo" :informaciondos="universidad" />
    </div>
</template>

<script>
    import {mapMutations, mapState} from "vuex"
    import API from "@/api"
    import CtaNav from './components/common/CtaNav.vue';
    import CtaFooter from "./components/common/CtaFooter";
    import CtaCallAction from './components/common/CtaCallAction.vue';

    export default {
        components: {
            CtaFooter,
            CtaNav,
            CtaCallAction
        },
        data() {
            return {
                render: false,
                infoCallAction: {},
                networks: [],
                contact: [],
                nav: [],
                logo: {},
                universidad: {},
                root: null
            }
        },
        async created() {
            this.setLoading(true);
            await this.getMenu();
            const {data} = await API.getEventos();
            this.setEventos(data.results.reverse());
            this.getInfo();
            this.getSocialNetwork();
            this.getParametros();
            if (this.$route.query.evento) {
                this.setIdEventoActual(this.$route.query.evento)
            } else {
                this.setIdEventoActual(data.results.find(evento => evento.actual === "1").id)
            }
            this.render = true
        },
        computed: {
            ...mapState(["eventos", "loading"]),
            ...mapState(["idEventoActual"]),
        },
        mounted: function () {
            this.root = document.documentElement;
        },
        methods: {
            ...mapMutations(["setIdEventoActual", "setEventos", "setLoading"]),
            async getInfo() {
                const params = {
                    "evento_fk" : this.idEventoActual,
                    format:  "json"
                };
                const {data} = await API.getInfo(params);
                let items = data.results;
                for (let i in items) {
                    this.infoCallAction = (items[i].codigo === 'GE01') ? items[i] : this.infoCallAction;
                    this.logo = (items[i].codigo === 'GE02') ? items[i] : this.logo;
                    this.universidad = (items[i].codigo === 'GE03') ? items[i] : this.universidad;
                    if (items[i].codigo === 'CO') {
                        this.contact.push(items[i]);
                    }
                }
            },
            async getSocialNetwork() {
                const {data} = await API.getSocialNetwork();
                this.networks = data.results;
            },
            async getParametros() {
                const params = {
                    "evento_fk" : this.idEventoActual,
                    format:  "json"
                };
                const {data} = await API.getParametros(params);
                const config = data.results[0];
                this.root.style.setProperty("--theme-color", config.color_principal);
                this.root.style.setProperty("--theme-color-light", config.color_secundario);
                this.root.style.setProperty("--text-color", config.color_letras_princial);
                this.root.style.setProperty("--text-color-dark:", config.color_letras_secundario);
            },
            async getMenu() {
                const params = {
                    "evento_fk" : this.idEventoActual,
                    format:  "json"
                };
                const {data} = await API.getMenu(params);
                const menu = data.results;
                for(let i in menu){
                    this.nav.push({
                        "nombre": menu[i].nombre,
                        "path": menu[i].link,
                        "items": await this.getHijosMenu(menu[i].id),
                    });
                }
                this.setLoading(false);
            },
            async getHijosMenu(id){
                const params = {
                    "menu_fk" : id,
                    format:  "json"
                };
                const {data} = await API.getItemMenu(params);
                const items = data.results;
                var itemsMenu = [];
                for (let i in items){
                    itemsMenu.push({
                        "nombre": items[i].nombre,
                        "path": items[i].link
                    })
                }
                return itemsMenu;
            },
        },
    }
</script>

<style>
    body {
        @apply text-text-color;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply text-text-color-dark m-0 font-bold;
        letter-spacing: -1.5px;
    }

    .lds-dual-ring {
        display: inline-block;
        width: 80px;
        height: 80px;
    }

    .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid var(--theme-color);
        border-color: var(--theme-color) transparent var(--theme-color) transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
